import React from 'react';
import { companyName, companyTagline } from '../data/content';
import './Hero.css';

function Hero() {
  return (
    <section className="hero">
      <div className="hero-content container">
        <h1>{companyName}</h1>
        <p className="tagline">{companyTagline}</p>
        <a href="#games" className="btn">Discover Our Games</a>
      </div>
    </section>
  );
}

export default Hero;