export const companyName = "Astake Games";
export const companyTagline = "Crafting Unforgettable Mobile Gaming Experiences";
export const companyIntro = "Astake Games is a passionate mobile game development company specializing in creating engaging and innovative games for Android and iOS platforms. Our team of talented developers and designers work tirelessly to bring unique gaming experiences to players worldwide.";

export const contactInfo = "We'd love to hear from you! Whether you have questions about our games, want to report an issue, or are interested in partnering with us, please don't hesitate to reach out using the form below or through our alternative contact methods.";

export const privacyPolicyContent = [
  {
    title: "Introduction",
    content: "At Astake Games, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your data when you use our mobile games and visit our website."
  },
  {
    title: "Information We Collect",
    content: "We may collect personal information such as your name, email address, and device information when you use our games or contact us. We also collect non-personal information such as gameplay statistics and app usage data to improve our services."
  },
  {
    title: "How We Use Your Information",
    content: "We use the collected information to provide and improve our games, respond to your inquiries, send you important updates, and analyze user behavior to enhance our services. We may also use your data for marketing purposes, but only with your explicit consent."
  },
  {
    title: "Data Sharing and Disclosure",
    content: "We do not sell your personal information to third parties. We may share your data with trusted service providers who assist us in operating our business, but they are obligated to keep your information confidential. We may also disclose your information if required by law or to protect our rights and safety."
  },
  {
    title: "Data Security",
    content: "We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security."
  },
  {
    title: "Your Rights and Choices",
    content: "You have the right to access, correct, or delete your personal information. You can also opt-out of marketing communications at any time. To exercise these rights or if you have any questions about your data, please contact us using the information provided on our Contact page."
  },
  {
    title: "Children's Privacy",
    content: "Our games and services are not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe we may have collected information about a child, please contact us immediately."
  },
  {
    title: "Changes to This Policy",
    content: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last updated' date at the bottom of this page. We encourage you to review this Privacy Policy periodically for any changes."
  }
];

