import React from 'react';
import { games } from '../data/games';
import './GameShowcase.css';

function GameShowcase() {
  return (
    <section id="games" className="game-showcase container">
      <h2>New Releases</h2>
      <div className="game-grid">
        {games.map((game, index) => (
          <div key={index} className="game-card">
            <img src={game.thumbnail} alt={game.title} />
            <h3>{game.title}</h3>
            <p>{game.description}</p>
            <div className="game-platforms">
              {game.platforms.includes('android') && <i className="fab fa-android"></i>}
              {game.platforms.includes('ios') && <i className="fab fa-apple"></i>}
            </div>
            <div className="game-links">
              {game.downloadLink && (
                <a href={game.downloadLink} className="btn btn-download">Download</a>
              )}
              {game.storeLink && (
                <a href={game.storeLink} className="btn btn-store">Store Page</a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default GameShowcase;