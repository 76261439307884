import React from 'react';
import Hero from '../components/Hero';
import GameShowcase from '../components/GameShowcase';
import { companyIntro } from '../data/content';
import './Home.css';

function Home() {
  return (
    <div className="home">
      <Hero />
      <section className="intro container">
        <h2>Welcome to Astake Games</h2>
        <p>{companyIntro}</p>
      </section>
      <GameShowcase />
    </div>
  );
}

export default Home;