import React from 'react';
import { privacyPolicyContent } from '../data/content';
import './Privacy.css';

function Privacy() {
  return (
    <div className="privacy-page container">
      <h1>Privacy Policy</h1>
      <div className="privacy-content">
        {privacyPolicyContent.map((section, index) => (
          <section key={index}>
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </section>
        ))}
      </div>
      <div className="privacy-footer">
        <p>Last updated: {new Date().toLocaleDateString()}</p>
        <p>If you have any questions about our Privacy Policy, please <a href="/contact">contact us</a>.</p>
      </div>
    </div>
  );
}

export default Privacy;