import React from 'react';
import ContactForm from '../components/ContactForm';
import { contactInfo } from '../data/content';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-page container">
      <h1>Contact Us</h1>
      <p>{contactInfo}</p>
      <ContactForm />
      <div className="alternative-contact">
        <h2>Alternative Contact Methods</h2>
        <p>Email: info@astake.online</p>
        <div className="social-links">
          <a href="https://twitter.com/astakegames" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://facebook.com/astakegames" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://instagram.com/astakegames" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;