export const games = [
    {
      title: "Cosmic Quest",
      thumbnail: "/images/cosmic_quest.png",
      description: "Challenge your mind with hundreds of brain-teasing puzzles in this addictive puzzle game.",
      platforms: ["android", "ios"],
      downloadLink: "#",
      storeLink: "#"
    },
    {
        title: "Cosmic Quest",
        thumbnail: "/images/cosmic_quest.png",
        description: "Challenge your mind with hundreds of brain-teasing puzzles in this addictive puzzle game.",
        platforms: ["android", "ios"],
        downloadLink: "https://play.google.com/store/apps/details?id=com.AstakeGames.CosmicQuest",
        storeLink: "#"
      },
  ];